<template>
    <Teleport v-if="renderModal" :to="to" :disabled="disableTeleport">
        <OMediaQueryProvider v-slot="{ xs }">
            <div ref="modalRef" class="modal fade" :class="class" :data-grid-master-id="masterGrid?.id" :data-bs-focus="dataBsFocus" :data-bs-backdrop="dataBsBackdrop" v-bind="$attrs">
                <div v-if="title" class="modal-dialog" :class="dialogClass">
                    <div class="modal-content">
                        <div class="modal-header" :class="{ 'flex-column': xs }">
                            <template v-if="xs">
                                <div class="d-flex align-items-center w-100">
                                    <h5 class="modal-title">{{ title }}</h5>
                                    <button type="button" class="btn-close" data-bs-dismiss="modal"
                                        aria-label="Close"></button>
                                </div>

                                <!-- @slot
                                @ignore -->
                                <slot name="headerAction"></slot>
                            </template>

                            <template v-else>
                                <h5 class="modal-title">{{ title }}</h5>
                                <!-- @slot
                                @ignore -->
                                <slot name="headerAction"></slot>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </template>
                        </div>
                        <slot></slot>
                    </div>
                </div>
                <slot v-else></slot>
            </div>
        </OMediaQueryProvider>
    </Teleport>
</template>

<script setup>
/**
* Modal component, check snippets how to use open buttons
* @example <!-- Opening modal through ref -->
<button class="btn btn-primary" @click="$refs.myModalRef.show">My Modal</button>
<OModal ref="myModalRef">
<div class="modal-dialog">
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title">Modal title</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
            <p>Modal body text goes here.</p>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
            <button type="button" class="btn btn-primary">Save changes</button>
        </div>
    </div>
</div>
</OModal>
* @example <!-- Opening modal through v-target directive -->
<button class="btn btn-primary" v-target:myModal >My Modal</button>
<OModal name="myModal">
...
</OModal>
* @definition
**/
import { ref, nextTick, provide, inject, onBeforeUnmount, defineOptions } from 'vue';
import { InjectionKeys } from 'o365-utils';
import { useTarget, useNestedStack } from 'o365-vue-utils';
import OMediaQueryProvider from './Helpers.MediaQueryProvider.vue';

defineOptions({
    inheritAttrs: false
});

const props = defineProps({
    title: String,
    name: String,
    to: {
        type: String,
        required: false,
        default: 'body'
    },

    modalOptions: {
        type: Object,
        required: false
    },

    dialogClass: null,
    class: [String, Object],
    disableTeleport: {
        type: Boolean,
        required: false,
        default: false
    },
    alwaysRender: Boolean,
    dataBsFocus: {
        type: [Boolean, String],
        default: false
    },
    dataBsBackdrop: String,
    //movable: {
    //    type: Boolean,
    //    required: false,
    //    default: false
    //},

});
const emit = defineEmits([
    /**
    * The event is emitted when the modal is shown and the css transactions have finished
    * @param {Event} event The event returned by hidden.bs.modal listener
    */
    'shown',
    /**
    * The event is emitted when modal is transitioning to show
    * @param {Event} event The event returned by show.bs.modal listener
    */
    'show',
    /**
    * The event is emitted when the modal is hidden and the css transactions have finished
    * @param {Event} event The event returned by shown.bs.modal listener
    */
    'hidden',
    'hide'
]);

if (props.name) {
    const instance = Math.round(Math.random() * 100);
    useTarget({
        name: props.name,
        handler: () => show(),
    });
}

//--- Nested dropdown logic ---
const { currentNest, add: addToNest, remove: removeFromNest } = useNestedStack({
    injectinoKey: InjectionKeys.nestedStackKey,
});
const masterGrid = inject(InjectionKeys.dataGridControlKey, null);

const modal = ref(null);
const modalRef = ref(null);

const renderModal = ref(false);

const transitionPromise = ref(null);
let resolveTransition = null;

async function setupModal() {
    renderModal.value = true;
    transitionPromise.value = new Promise((res) => {
        resolveTransition = res;
    });
    await nextTick();
    modal.value = bootstrap.Modal.getOrCreateInstance(modalRef.value, props.movable ? { backdrop: false, ...(props.modalOptions ?? {}) } : props.modalOptions);
    addToNest(modalRef.value);

    modalHasDataGrid = !!modalRef.value.querySelector('.o365-data-grid');

    modalRef.value.addEventListener('show.bs.modal', onBsShow);
    modalRef.value.addEventListener('shown.bs.modal', onBsShown);
    modalRef.value.addEventListener('hidden.bs.modal', onBsHidden);
    modalRef.value.addEventListener('hide.bs.modal', onBsHide);
}

if (props.alwaysRender) {
    setupModal();
}

let modalHasDataGrid = false;
async function show() {
    if (!renderModal.value) {
        await setupModal();
    }
    if (modal.value) {
        modal.value.show();
        window.setTimeout(() => {
            if (resolveTransition) {
                resolveTransition();
                resolveTransition = null;
                transitionPromise.value = null;
            }
        }, 160);
    }
}

function hide(awaitNextTick = true) {
    if (modal.value) {
        if (modal.value._isTransitioning) {
            const shownEventHandler = () => {
                modalRef.value.removeEventListener('shown.bs.modal', shownEventHandler);
                modalRef.value.removeEventListener('hidden.bs.modal', hiddenEventHandler);
                modal.value.hide();
            };

            const hiddenEventHandler = () => {
                modalRef.value.removeEventListener('shown.bs.modal', shownEventHandler);
                modalRef.value.removeEventListener('hidden.bs.modal', hiddenEventHandler);
            }

            modalRef.value.addEventListener('shown.bs.modal', shownEventHandler);
            modalRef.value.addEventListener('hidden.bs.modal', hiddenEventHandler);
        } else {
            modal.value.hide();
        }
    } else if (renderModal.value && awaitNextTick) {
        nextTick().then(() => {
            hide(false);
        });
    }
}

const onBsShow = (e) => {
    emit('show', e);
};

const onBsShown = (e) => {
    if (modalHasDataGrid) {
        window.dispatchEvent(new Event('resize'));
    }
    emit('shown', e);
    window.addEventListener('keydown', handleKeyPress);
}

const onBsHidden = (e) => {
    emit('hidden', e);
    window.removeEventListener('keydown', handleKeyPress);
}

function onBsHide(e) {
    emit('hide', e);
}

const handleKeyPress = (event) => {
  if (event.key === 'Escape') {
    modal.value.hide();
  }
};

onBeforeUnmount(() => {
    if (renderModal.value) {
        removeFromNest();

        modalRef.value.removeEventListener('shown.bs.modal', onBsShown);
        modalRef.value.removeEventListener('show.bs.modal', onBsShow);
        modalRef.value.removeEventListener('hidden.bs.modal', onBsHidden);
        modalRef.value.removeEventListener('hide.bs.modal', onBsHide);
        if (modal.value) {
            if (modal.value._backdrop?._isAppended && modal.value?._backdrop?._element?.remove) {
                modal.value._backdrop._element.remove();
            }
            modal.value?.dispose();
        }
    }
});

provide(InjectionKeys.bootstrapTransitionPromiseKey, transitionPromise);

defineExpose({ modal, show, hide });

</script>
